<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card class="mt-2">
			<el-form ref="form" :model="form" :rules="rules" label-width="80px" size="small">
				<el-form-item label="学期" prop="semester_id">
				   <el-select v-model="form.semester_id" placeholder="请选择学期" style="width: 350px;">
<!-- 					<el-option label="无" value=""></el-option> -->
					<el-option v-for="(item,index) in semesters" :key="index"
						:label="item.year+'-'+item.tag" 
						:value="item.id">
					</el-option>
				   </el-select>
				</el-form-item>
				<el-form-item label="导出类型" prop="type_id" class="mb-2">
				   <el-select v-model="form.type_id" placeholder="请选择导出类型" style="width: 350px;">
					<el-option v-for="(item,index) in types" :key="index"
						:label="item.name" 
						:value="item.id">
					</el-option>
				   </el-select>
				</el-form-item>
				<el-form-item label="">
				   <el-button type="primary" size="medium" @click="handleSubmit">导 出</el-button>
				</el-form-item>
			</el-form>
			
		</el-card>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
		},
		data() {
			return {
				autoRequest: false,
				preUrl: '',
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
				},
				form:{
					semester_id:'',
					type_id:'',
				},
				rules:{
					semester_id: [
					  { required: true, message: '请选择学期', trigger: ['blur', 'change']  }
					],
					type_id: [
					  { required: true, message: '请选择导出类型', trigger: ['blur', 'change'] }
					],
				},
				semesters:[],
				types:[
					{id:1,'name':'教师荣誉'},
					{id:2,'name':'教师获奖'},
					{id:3,'name':'学生获奖'},
				],
			}
		},
		mounted() {
			this.getSemesters()
		},
		methods:{
			getSemesters(){
				this.axios.get('/manage/semester/all').then(res=>{
					if(res.status){
						this.semesters = res.data
					}
				})
			},
			handleSubmit(){
				this.$refs.form.validate(valid => {
				  if (valid) {
					  this.axios.get('/manage/semester/export_other',{
					  	params:this.form,
					  	responseType: 'arraybuffer',
					  }).then(res=>{
					  	  const aLink = document.createElement("a");
					  	  let blob = new Blob([res], { type: "application/vnd.ms-excel" });
					  	  aLink.href = URL.createObjectURL(blob);
					  	  var fileName = ''
					  	  if(this.form.type_id == 1){
					  		  fileName = '教师荣誉.xlsx'
					  	  }else if(this.form.type_id == 2){
					  		  fileName = '教师获奖.xlsx'
					  	  }else if(this.form.type_id == 3){
					  		  fileName = '学生获奖.xlsx'
					  	  }
					  	  aLink.download = fileName;
					  	  aLink.click();
					  	  document.body.appendChild(aLink);
					  })
				  }
				})
			},
		}
}
</script>

<style>
</style>